exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-freelances-js": () => import("./../../../src/pages/freelances.js" /* webpackChunkName: "component---src-pages-freelances-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-le-reseau-de-freelances-js": () => import("./../../../src/pages/le-reseau-de-freelances.js" /* webpackChunkName: "component---src-pages-le-reseau-de-freelances-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-freelance-js": () => import("./../../../src/templates/freelance.js" /* webpackChunkName: "component---src-templates-freelance-js" */),
  "component---src-templates-prestation-js": () => import("./../../../src/templates/prestation.js" /* webpackChunkName: "component---src-templates-prestation-js" */)
}

